import styled, { css } from 'styled-components';
import {
  CategoryCapsuleIcon,
  Colors,
  MediaQuery,
  Theme,
} from '@pelando/components';
import {
  OnboardingContainerSteps,
  OnboardingContinueButton,
  OnboardingTextContainer,
  OnboardingTitle,
} from '../style';

// TODO: Quando comunidades for permanente remover estilos de categorias daqui

export const SearchContainer = styled(OnboardingContainerSteps)`
  justify-content: flex-end;

  @media ${MediaQuery.SCREEN_MD_UP} {
    justify-content: unset;
  }
`;

export const SearchTextContainer = styled(OnboardingTextContainer)`
  top: 80px;
  background-color: rgb(${Theme.colors.Brand});
  width: 100vw;
  margin: 0;
  padding: 0 12% 0 16px;
  z-index: 2;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: unset;
    width: unset;
    margin: unset;
    padding: 0;
  }
`;

export const SearchTextContainerGradient = styled.div`
  display: flex;
  position: fixed;
  height: 16px;
  width: 96vw;
  top: 300px;
  background: linear-gradient(
    to bottom,
    rgb(${Theme.colors.Brand}) 20%,
    rgba(${Theme.colors.Brand}, 0) 100%
  );
  z-index: 2;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const CategoriesContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 220px 0 100px;
  padding: 0 16px;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 0;
    max-height: 100%;
    overflow: hidden;
    margin: 32px 0;
    gap: 14px 12px;
  }
`;

export const CategoriesContainerWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const ContinueButtonWrapperMobile = styled.div<{ disabled?: boolean }>`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  position: fixed;
  justify-content: center;
  background-color: rgb(${Theme.colors.Brand});
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(${Theme.colors.Brand}) 50%,
    rgba(${Theme.colors.Brand}, 0) 100%
  );

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: unset;
    height: 44px;

    display: ${({ disabled }) => (disabled ? 'flex' : 'flex')};
  }
`;

export const ContinueButton = styled(OnboardingContinueButton)`
  && {
    border: solid 2px rgb(${Colors.Silver});
    background-color: rgb(${Colors.White});

    i {
      color: rgb(${Theme.colors.Brand});
    }

    &:disabled {
      display: none;
    }

    @media ${MediaQuery.SCREEN_MD_UP} {
      &:disabled {
        i {
          color: rgb(${Colors.Gray});
        }
        display: flex;
      }
    }
  }
`;

// Comunidades

export const CommunitiesStepContainer = styled(OnboardingContainerSteps)`
  justify-content: space-around;
  height: 100%;
`;

export const CommunitiesTextContainer = styled(OnboardingTextContainer)`
  position: unset;
  margin-bottom: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 0;
  }
`;

export const CommunitiesTitle = styled(OnboardingTitle)`
  margin: 0 0 16px 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 0 0 8px 0;
  }
`;

const categoriesWrapperBorderGradientStyle = css`
  content: '';
  display: flex;
  position: absolute;
  height: 16px;
  width: 96vw;

  z-index: 2;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const CommunitiesContainerWrapper = styled.div`
  overflow-y: auto;
  max-height: 394px;
  margin-bottom: 80px;
  position: relative;
  overflow-x: hidden;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 0;
  }

  &::before {
    ${categoriesWrapperBorderGradientStyle}
    top: 0;
    background: linear-gradient(
      to bottom,
      rgb(${Theme.colors.Brand}) 20%,
      rgba(${Theme.colors.Brand}, 0) 100%
    );
  }

  &::after {
    ${categoriesWrapperBorderGradientStyle}
    bottom: 0;
    background: linear-gradient(
      to top,
      rgb(${Theme.colors.Brand}) 20%,
      rgba(${Theme.colors.Brand}, 0) 100%
    );
  }
`;

export const CommunityCapsuleIcon = styled(CategoryCapsuleIcon)`
  p {
    white-space: nowrap;
  }
`;

export const CommunitiesContainer = styled.div`
  max-height: 394px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 16px;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 0;
    max-height: 100%;
    overflow: hidden;
    gap: 14px 12px;
  }
`;
